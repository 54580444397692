<template>
<div>

</div>
</template>

<script>
export default {
name: "NotFound2",
  created:function (){

  },
  methods:{
    load_a:function (){
    }
  }
}
</script>

<style scoped>
/* Main styles */

</style>
